/* eslint-disable no-undef */
import React, { Component } from 'react';
import {biometricFormFields} from '../../../mockData.js'
import PropTypes from 'prop-types';
import withValidations from "../WithValidations";
import { ContentWrapper, ButtonWrapper, NavigationBtn, FormWrapper, SectionContainer, StyledBiometricInput, StyledLabel, StyledBiometricDatetime, DownCaret} from '../MultiStepForm/styles'
import {
  fetchSearchPeople,
  saveUserInformation,
  getUserBiometricDetails,
  saveBiometricInfo
} from "../../redux/actions";
import { connect } from 'react-redux';
import Waiting from '../Waiting'
import Datetime from 'react-datetime';
import RadioButton from "../CustomRadioButton";
class UserBiometric extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      openDatePicker: false,
      birthDate: '',
      fname: '',
      lname: '',
      address1: '',
      city: '',
      state: '',
      mail: '',
      companyName: '',
      phoneNumber: '',
      postalCode: '',
    }
  }

  // biometricFormFields= [
  //   {
  //     "title": "first",
  //     "inputFields": [
  //       { "type": "text", "placeholder": "First Name", "name": "fname", "label": "First Name", "maxLength": 30},
  //       { "type": "text", "placeholder": "Last Name", "name": "lname", "label": "Last Name", "maxLength": 30},
  //       { "type": "text", "placeholder": "Work Email", "name": "mail", "label": "Email", "maxLength": 50},
  //       { "name": "birthday", "label":  "Birthday"},
  //       { "type": "text", "placeholder":  "Employer", "name": "companyName", "label": "Company Name"}
  //     ]
  //   }
  // ]  

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDatePicker: false
      });
    }
  };

  showDatePicker = () => {
    this.setState((prev) => ({
      openDatePicker: !prev.openDatePicker
    }))
  };

  changeDate = (date) => {
    this.setState({
      birthDate: date,
      openDatePicker: false
    });
  };

  onDateBlur = (date) => {
    const { saveUserInformation } = this.props;
    saveUserInformation('birthday', date);
    this.setState({ openDatePicker: false });
  };

  closeDate = (e) => {
    if(e.target.className === 'form-control') {
      if(this.state.count>0 && e.target.className === 'form-control' && this.state.openDatePicker) {
        this.setState({ openDatePicker: false })
      }
      else {
        this.setState({ count: 1 })
      }
    }
  };

  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if(charCode === 46) evt.preventDefault();
    if(charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  render () {
    const {openDatePicker, birthDate} = this.state;
    const {onChange, biometricDetails, handleChange, values, goNext, toggleRadioButton} = this.props;
    if(!biometricDetails) {
      return <Waiting />
    }
    const yesterday = Datetime.moment().subtract(13, 'years');
    const previousLimit = Datetime.moment().subtract(100, 'years');
    const valid = (currentDate) => currentDate.isBefore(yesterday) && currentDate.isAfter(previousLimit);
    let isDisabled = false;
    if(_.isNull(values['gender']) || _.isEmpty(values['gender'])){
      isDisabled = true;
    }
    return <ContentWrapper>
      <div>
        <span>1</span>
        <span>Personal Details</span>
        <div>
          <RadioButton id="1" handler={toggleRadioButton} name = "gender" value={'Male'} isChecked={ values['gender'] == 'Male'} label={'Male'} userBiometric={1}/>
          <RadioButton id="2" handler={toggleRadioButton} name = "gender" value ={'Female'} isChecked={values['gender'] == 'Female'} label={'Female'} userBiometric={1}/>
        </div>
      </div>
      {
        biometricFormFields.map((section, index)=>
          <FormWrapper key={section.title + index} largePadding={section.title == 'first' ? '0 20px 0 30px' : '0 20px 0 30px'}>
            {
              section.inputFields.map((field, index) => {
                if (field.name === 'date') {
                  return (
                    <SectionContainer style={{position: 'relative'}} key={field.name + index} onClick={(e) => this.closeDate(e)} ref={(node) => this.setDatePickerWrapper(node)}>
                      <StyledLabel>{field.label}</StyledLabel>
                      <StyledBiometricDatetime
                        inputProps={{ placeholder: "Date of Birth", readOnly: true}}
                        open={openDatePicker}
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        onChange={this.changeDate}
                        value={values[field.name] || ''}
                        isValidDate={valid}
                        viewDate={birthDate ? birthDate : yesterday}
                        onBlur={this.onDateBlur}
                        onFocus={this.showDatePicker}
                        viewMode="years"
                      />
                      <DownCaret opened={true}/>
                    </SectionContainer>
                  )
                }
                if(field.name !== 'gender') {
                  return (
                    <SectionContainer key={field.name + index}>
                      <StyledLabel>{field.label}</StyledLabel>
                      <StyledBiometricInput
                        type={field.type}
                        placeholder={field.placeholder}
                        name={field.name}
                        onChange={(e) => onChange(e, handleChange)}
                        onKeyPress={(e) => ((field.name ==='phoneNumber' || field.name ==='postalCode') && this.isNumberKey(e))}
                        maxLength={field.name === 'phoneNumber'? 12 : field.maxLength}
                        value={values[field.name] || ''}
                        fullWidth={1}
                        readOnly={field.name == 'fname' || field.name == 'lname' || field.name=='mail' || field.name=='birthday' || field.name=='companyName'}
                        disabled={field.name == 'fname' || field.name == 'lname' || field.name=='mail' || field.name=='birthday' || field.name=='companyName'}
                      />
                    </SectionContainer>
                  )
                }
              })
            }
          </FormWrapper>
        )
      }
      <ButtonWrapper>
        <NavigationBtn
          type="submit"
          onClick={goNext}
          float={1}
          disabled={isDisabled}
        >
          next
          <i className="fas fa-chevron-right" />
        </NavigationBtn>
      </ButtonWrapper>
    </ContentWrapper>
  }
}

UserBiometric.propTypes = {
  onChange: PropTypes.func,
  userData: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  saveUserInformation: PropTypes.func,
  getUserBiometricDetails: PropTypes.func,
  saveBiometricInfo: PropTypes.func,
  history: PropTypes.object,
  biometricDetails: PropTypes.object,
  handleChange: PropTypes.func,
  goNext: PropTypes.func,
  values: PropTypes.object,
  toggleRadioButton: PropTypes.func
};
const mapStateToProps = (state) => ({
  userData: state.register.userData,
  biometricDetails: state.peopleHome.biometricDetails
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchPeople: (data, cb) => dispatch(fetchSearchPeople(data, cb)),
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
  getUserBiometricDetails: (userId) => dispatch(getUserBiometricDetails(userId)),
  saveBiometricInfo: (userDetail) => dispatch(saveBiometricInfo(userDetail))
});
export default withValidations(connect(mapStateToProps, mapDispatchToProps)(UserBiometric))